@font-face {
  font-family: 'Darker Grotesque';
  src: url('../public/DarkerGrotesque-Regular.ttf');
}

@font-face {
  font-family: 'Helvetica Neue';
  src: url('../public/HelveticaNeueLt.ttf');
}

@font-face {
  font-family: 'Helvetica Neue Bold';
  src: url('../public/HelveticaNeueMed.ttf');
}

body,
a,
b,
div,
button,
select,
input,
tspan,
text,
label {
  font-family: 'Helvetica Neue', Helvetica, Arial, 'Lucida Grande', sans-serif !important;
  /* letter-spacing: 0.5px; */
  /* font-weight: 600 !important; */
}

.login-links {
  padding: 0px 7px !important;
  color: #000 !important;
  background: transparent !important;
  font-weight: 600 !important;
}

.login-links:hover {
  text-decoration: underline !important;
}

.Login-head {
  margin-bottom: 0px !important;
}

.Login-subhead {
  margin-top: 5px !important;
  font-size: 36px;
  text-align: left;
  margin-bottom: 15px;
  letter-spacing: -0.5px;
}

.social-btn {
  margin: 0 auto !important;
  margin-top: 10px !important;
  width: 100% !important;
  font-size: 15px !important;
  height: 40px !important;
}

.social-btn > div {
  display: inline-flex !important;
}

.social-btn > div > div > svg {
  width: 18px !important;
}

.main-content {
  background: #f7f7f7 !important;
}

.ag-theme-alpine .ag-header {
  background-color: #223354;
  /* --ag-header-foreground-color: #223354; */
}

.ag-header-cell-text,
.ag-icon-filter {
  color: #fff !important;
}

.ag-row-odd {
  background-color: #e8eaed !important;
}

.ag-cell-value {
  font-weight: 600 !important;
}

/* .ag-theme-alpine .ag-ltr .ag-cell {
  line-height: 4em;
} */

.MuiTab-root {
  font-size: 13px !important;
}

input,
.MuiSelect-select {
  background: #fff !important;
}

.Mui-selected {
  background: #f8f8f8 !important;
  box-shadow: none !important;
  border-radius: 0px !important;
  color: #092b71 !important;
  border-bottom: 1px solid #092b71 !important;
}

.ag-theme-alpine .ag-row-selected {
  background-color: rgb(255 255 255 / 30%);
}

.MuiDialog-paper {
  max-width: 700px !important;
  width: 700px !important;
  overflow-x: hidden;
}

.MuiPaper-root::-webkit-scrollbar {
  width: 3px;
}

.MuiLabel-success,
.MuiLabel-error {
  vertical-align: middle !important;
}

.MuiSelect-outlined:focus {
  outline: none !important;
}

.ag-theme-alpine
  .ag-ltr
  .ag-has-focus
  .ag-cell-focus:not(.ag-cell-range-selected) {
  border: none !important;
}

.error-message {
  margin: 0 auto !important;
  display: block !important;
  margin-top: 10px !important;
}

.weak-password {
  background: #dc3545 !important;
}

.medium-password {
  background: #ffc107 !important;
}

.strong-password {
  background: #28a745 !important;
}

.password-text {
  font-size: 11px !important;
  margin-top: 0px !important;
  display: block;
  text-align: center !important;
  color: #fff;
  padding-top: 5px !important;
  text-shadow: 1px 1px 3px #0e0e0e;
  letter-spacing: 0.5px;
}

.statusBar {
  width: 100% !important;
  height: 25px !important;
  border: 1px solid #cbcbcb !important;
  border-radius: 3px !important;
}

.password-cont {
  width: 100% !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
}

.password-conts {
  width: 99% !important;
  margin: 0 auto !important;
  margin-bottom: 15px !important;
  margin-left: 0px !important;
}

.ag-theme-alpine .ag-checkbox-input-wrapper {
  font-family: 'agGridAlpine' !important;
}

/* .ag-column-select-virtual-list-item[aria-posinset='1'] {
  display: none !important;
  height: 0px !important;
} */

.MuiListSubheader-sticky {
  position: relative !important;
}

@media (min-width: 1025px) {
  #mobile-ethnicity-filter {
    display: none !important;
  }
  #map-analytics-filter {
    display: none !important;
  }
  .MobileHeader-menu-wrapper {
    display: none !important;
  }
  .country-filter-panel{
    width: 250px !important;
  }
}

@media (max-width: 1024px) {
  .country-filter-panel{
    width: 250px !important;
  }
  .MobileHeader-wrapper {
    width: 50% !important;
  }

  .logotext-Wrapper {
    font-size: 0.9em !important;
  }

  .lastupdated-Date {
    font-size: 12px !important;
  }

  .MuiPaper-root {
    min-width: max-content !important;
    max-width: max-content !important;
    width: max-content !important;
  }

  #fristFilters {
    padding-top: 25px !important;
  }

  #mobile-ethnicity-filter {
    display: block !important;
    float: right;
    width: 100%;
  }

  #map-analytics-filter {
    display: block !important;
    float: right;
  }

  #mobile-header-wrapper {
    left: 0px !important;
  }

  #mobile-header-wrapper2 {
    left: 0px !important;
    height: max-content;
  }

  #mobile-header-wrapper2 > div {
    height: 80px;
    overflow: auto;
  }

  .graphs-wrapper {
    margin-top: 3% !important;
  }
}

@media (min-width: 1023px) {
  #Toogle-sidebar {
    display: none;
  }
}

@media (min-width: 1280px) {
  .MuiContainer-root {
    max-width: -webkit-fill-available !important;
  }
}

.alpine-custom-headers .ag-header {
  background-color: #b11f49 !important;
}

/* .ag-cell-value, .ag-group-value {
  overflow: visible !important;
} */

.ag-header-cell-label {
  justify-content: center !important;
}

.ag-theme-alpine .ag-radio-button-input-wrapper {
  font-family: 'agGridAlpine' !important;
}

/* .ag-cell-wrapper {
  display: block !important;
  width: inherit !important;
  padding: 0px 5px !important;
} */

.MuiSwitch-colorError > .MuiSwitch-thumb {
  background: #f44336 !important;
}

.MuiSwitch-colorSuccess > .MuiSwitch-thumb {
  background: #4caf50 !important;
}

.MuiSwitch-colorSuccess.MuiSwitch-track {
  background: #4caf50 !important;
}

.MuiLoadingButton-loadingIndicator {
  color: #b21a43 !important;
}

.Mui-disabled {
  visibility: hidden !important;
}

/* .ag-header {
  position: sticky !important;
  top: 0px !important;
  z-index: 5 !important;
}


.ag-root-wrapper,
.ag-root-wrapper-body,
.ag-root {
    display: unset !important;
} */

.apexcharts-toolbar {
  display: none !important;
}



#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(2),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(3),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(4),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(5),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(7),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(8),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(9),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(10),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(12),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(13),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(14),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(15),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(17),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(18),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(19),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(20),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(22),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(23),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(24),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(25),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(27),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(28),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(29),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(30),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(32),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(33),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(34),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(35),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(37),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(38),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(39),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(40),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(42),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(43),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(44),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(45),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(46),
#dailyballot > div > svg > .apexcharts-graphical > g > g > text:nth-child(47) {
  display: none !important;
}

#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(2),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(3),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(4),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(5),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(7),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(8),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(9),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(10),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(12),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(13),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(14),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(15),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(17),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(18),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(19),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(20),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(22),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(23),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(24),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(25),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(27),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(28),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(29),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(30),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(32),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(33),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(34),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(35),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(37),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(38),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(39),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(40),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(42),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(43),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(44),
/* #dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(45), */
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(46),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(47),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(48),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(49),
#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:nth-child(50) {
  display: none !important;
}


#dailyballot > div > svg > .apexcharts-graphical > g > g > text:last-child {
  display: block !important;
}

#dailyballot2 > div > svg > .apexcharts-graphical > g > g > text:last-child {
  display: block !important;
}

.MuiDialog-paper {
  /* min-width: 90%; */
}

.VoterTurnoutEthnicity > div > svg > .apexcharts-graphical > g > g > text {
  display: none !important;
}

/* 
g[seriesName="TotalxEthnicity"] {
  display: none !important;
}

.apexcharts-datalabels:nth-child(1)  {
  display: none !important;
} */

.MuiMenuItem-root {
  border-bottom: 0px !important;
  padding: 0px !important;
  width: 100% !important;
}

#filterOptionsAdmin > .MuiDialog-container > .MuiPaper-root {
  width: 400px !important;
  max-width: 400px !important;
  margin: 0 auto !important;
}

#filterOptions > .MuiDialog-container > .MuiPaper-root {
  width: 400px !important;
  max-width: 90% !important;
  margin: 0 auto !important;
}

.country-filter-panel {
  padding: 3px 10px;
  border: 1px solid #ddd;
  width: 100%;
  text-align: left;
  border-radius: 4px;
  z-index: 2;
  position: relative;
  background: #fff !important;
}

.filter-header {
  display: block;
  /* justify-content: start;
  align-items: center; */
  cursor: pointer;
  padding: 2px 0;
  font-size: 14px;
  font-weight: 900;
}

.filter-header button {
  background: none;
  border: none;
  font-size: 18px;
  cursor: pointer;
  font-size: 14px;
  font-weight: 500;
}

.country-options {
  padding-left: 00px;
  max-height: 300px;
  overflow-y: auto;
  scrollbar-width: thin;
}

.filter-header > label {
  font-size: 13px;
  font-weight: 900;
  cursor: pointer;
}

.filter-header > label > input {
  vertical-align: bottom;
  z-index: 10;
  position: relative;
}

.country-options > label {
  margin: 0px 0;
  font-size: 14px;
  font-weight: 500;
  scrollbar-width: thin;
  color: #000;
  width: 100%;
  display: inline-block;
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
}

input[type='checkbox'] {
  margin-right: 8px;
}

.filter-header > h3 {
  margin: 0px 0px 0px 0px !important;
  font-weight: 900;
  font-size: 14px;
}

.search-input {
  width: 100%;
  padding: 4px 5px;
  margin-bottom: 10px;
  border-top: 1px solid #ccc;
  border-bottom: 1px solid #ccc;
  border-radius: 0px;
  box-sizing: border-box;
}

.country-options > label > input {
  accent-color: #092b71;
  vertical-align: middle;
  width: max-content;
}

.filter-header > input {
  accent-color: #092b71;
  vertical-align: middle;
  width: max-content;
}


.mui-search-icon {
  color: grey;
  width: 0.7em !important;
  vertical-align: middle;
}

.search-input-wrapper {
  border: none !important;
  font-size: 12px;
  outline: none !important;
  vertical-align: middle !important;
  background: transparent !important;
}

.pagination-controls {
  display: flex;
  align-items: center;
  justify-content: end;
  background: #fff;
  padding: 8px 5px;
}

.pagination-controls button {
  background-color: #092b71;
  color: #fff;
  border: none;
  padding: 8px 12px;
  margin: 0 5px;
  border-radius: 5px;
  cursor: pointer;
  font-size: 14px;
  transition: background-color 0.3s ease;
}

.pagination-controls button:disabled {
  background-color: #d0d0d0;
  cursor: not-allowed;
}

.pagination-controls button:hover:not(:disabled) {
  background-color: #092b71;
}

.pagination-controls .page-info {
  font-size: 16px;
  margin: 0 10px;
  color: #333;
}


.ag-root-wrapper {
  display: flex !important;
  flex-direction: column !important;
}


.ag-column-drop-wrapper { order: 2 !important; }
.ag-paging-panel { order: 1 !important; }
.ag-root-wrapper-body { order: 3 !important; }


.ag-paging-panel {
  justify-content: flex-start !important;
}